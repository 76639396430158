import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';

const API_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  // public methods
  login(email: string, password: string): Observable<any> {
    //Note: to use the same myturn.tools api for the admin pane and to  block other user logins into the admin panel
    //I have updated the admin panel login process and make changes into the admin panel to work according to that.
    // Also SuperAdmin, Admin, Parter can not login into the BackOffice so I have also updated the login api to block that too.   
    
    var data = "grant_type=password&scope=" + encodeURIComponent(JSON.stringify({ 'cliente_id': email, 'token': password, 'isLoginFromAdminPanel': true }));
    return this.http.post<any>(`${API_URL}/token`, data);

    // old work
    // var data='username='+email+'&password='+password+'&grant_type=password';
    // return this.http.post<any>(`${API_URL}/token`, data);
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_URL}/forgot-password`, {
      email,
    });
  }

  getUserByToken(token): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(`${API_URL}/api/utilizador/getuserinfo`, {
      headers: httpHeaders,
    });
  }
}
