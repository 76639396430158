// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appVersion: 'v723demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  recaptcha_SiteKey: '6LeNJqsaAAAAAKIr1MbVuHp63mQs4ZoOB9zCpZWB',
  isMockEnabled: true,
  BaseEmailHostName: '@nt4solutions.com',
  apiUrl: 'https://myturn.tools',
  signlarUrl: 'https://myturn.tools/signalr',
  backOfficeURL: 'https://myturn.site',
  // backOfficeURL: 'https://localhost:44301',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
