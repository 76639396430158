import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.authService.logout();
    return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if(next.data.allowedRoles && next.data.allowedRoles.length > 0) {
      const allowedRoles = next.data.allowedRoles;
      const isAuthorized = this.authService.isAuthorized(allowedRoles);
      
      if(!isAuthorized) {
        // if not authorized, show access denied message
        this.router.navigate(['/error/404']);
        //error/404
      }
      return isAuthorized;
    }

    return true;
  }
}
