export class AuthModel {
  accessToken: string;
  refreshToken: string;
  expiresIn: Date;

  setAuth(auth: any) {
    this.accessToken = auth.access_token;
    this.refreshToken = auth.refreshToken;
    this.expiresIn = auth.expires_in;
  }
}
