// USA
export const locale = {
  lang: 'pt',
  data: {
    BUTTON: {
      BACK: 'Voltar',
      CANCEL: 'Cancelar',
      NEXT_STEP: 'Próxima',
      PREVIOUS: 'Anterior',
      RESET: 'Reset',
      SUBMIT: 'Submit',
      SAVE: 'Guardar',
      PRIVIEW: 'Preview',
      CHECKALL: 'Check All',
    },
    BUSINESS: {
      LABEL: {
        ADD_BUSINESS: 'Adicionar área de negócios',
        BUSINESS_AREA: 'Área de Negócios',
        EDIT_BUSINESS: 'Editar área de negócios',
        ICONE: 'Ícone',
        ICON_REQ: 'Ícone necessário.',
        NEW_BUSINESS_AREA: 'Nova Área de Negócios',
      }
    },
    CLIENT: {
      LABEL: {
        ACC_CREATED: 'Conta Criada',
        ÁREA_DE_NEGÓCIO: 'Área de Negócio',
        ÁREA_DE_NEGÓCIO_REQ: 'Área de Negócio é Obrigatória.',
        AVALIACAO_TOKEN: 'Avaliacao Token',
        AVALIACAO_TOKEN_REQ: 'Avaliacao Token é Obrigatória.',
        C_PASSWORD: 'Confirme a Password',
        C_PASSWORD_REQ: 'É necessário confirmar a senha',
        C_PASSWORD_MATCH: 'Confirmar que a senha deve ser igual à senha',
        Celcius: 'Celcius',
        CHOSEN: 'Escolhido',
        CLIENT: 'Cliente',
        CLIENT_NAME: 'Nome do cliente',
        CLNTINFO: 'Informação do Cliente',
        COMPANY: 'Empresa Nome',
        COMPLT_COMPLT: 'Preenchimento Completo',
        COMPLT_REQUEST: 'Registro Completo',
        COMRQ: 'Nome da empresa é obrigatório',
        CONFIRM_CRT_ACC: 'Todos os dados foram preenchidos. Clique no botão para confirmar a criação de conta.',
        CONTACT: 'Contato',
        CONTACT_INFO: 'Informação de Contacto',
        CONTACT_And_LOGIN_INFORMATION: 'Informações de contato e login',
        COR_DO_BANNER: 'Cor do banner',
        COR_DO_TEXTO: 'Cor do texto',
        CLICK_CONFIRM: 'Todos os dados foram preenchidos. Clique no botão para confirmar a criação de conta.',
        CONFIGURAÇÃO: 'Configuração do cliente',
        CONTACT_STATUS: 'Selecionar Contrato Status',
        CONTACT_STATUS_REQ: 'O status do contrato é obrigatório.',
        CONTRACT_PERIOD: 'Selecionar Contrato Periodo',
        CONTRACT_PERIOD_REQ: 'Contrato Periodo é obrigatório.',
        CREATE_ACCOUNT: 'Criar conta',
        CREATION_DATE: 'Data Criacao',
        CREATION_DATE_REQ: 'O Data Criacao é obrigatório.',
        CONTRACT_START_DATE: 'Contract Start Date',
        CONTRACT_START_DATE_REQ: 'Contract Start is required.',
        CONTRACT_END_DATE: 'Contract End Date',
        CONTRACT_END_DATE_REQ: 'Contract End is required.',
        CLIENT_LIST: 'Lista de clientes',
        Close: 'Fechar',
        CREATEDDATE: 'Data de criação',
        CREATE_LOCATION: 'Criar Local',
        CREATE_ENTIDADE: 'Create Entidade',
        Culture: 'Culture',
        DESCRIÇÃO: 'Descrição',
        DESCRIÇÃO_REQ: 'Descrição é Obrigatória',
        DIVISÃO_ADMINISTRATIVA: 'Divisão Administrativa',
        DYNAMIC_FORM: 'Módulo Formularios Dinamicos',
        ENTIDADE_LOCAL: 'Entidade Local',
        EMAIL_REQ: 'E-mail é obrigatório',
        EMAIL: 'Email',
        EDIT_CLIENT: 'Editar cliente',
        ENTER_CONTACT_STATUS: 'Enter Contrato Status',
        ENTER_CONTARCT_PERIOD: 'Enter Contrato Periodo',
        ENTER_LICENSING_KEY: 'Enter Chave Licenciamento',
        ENTER_LICENSING_TYPE: 'Enter Tipo Licenciamento',
        ENTER_MAX_LOGIN_ALLOWED: 'Enter Max Login Allowed',
        ENTER_NR_LOCATIONS: 'Enter Nr Locais',
        ENTER_NR_ENTITES: 'Enter Nr Entidades',
        ENTER_NR_USERS: 'Enter Nr Utilizadores',
        ENTIDADE: 'Entidade',
        ERROSTACKTRACE: 'Error Stacktrace',
        EXCEÇÃO_INTERNA_DE_ERRO: 'Exceção Interna',
        ESTILO_A_APLICAR_NA_ÁREA_DE_FUNCIONÁRIO: 'Estilo a aplicar na Área de Funcionário',
        EVAL_MODULE: 'Módulo Avaliações',
        Fahrenheit: 'Fahrenheit',
        GO_TO_HOME_PAGE: 'Ir para a página de entrada',
        GRACE_PERIOD: 'Gracing Period (in days)',
        GRACE_PERIOD_REQ: 'Gracing Period são necessários.',
        MORADA: 'Morada',
        INDCTSRVPROAREA: 'Selecionar a Área de Prestação de Serviço',
        ID: 'Id',
        KEY_GEN: 'Chave Ger',
        LOCAL: 'Local',
        LOGIN: 'Conecte-se',
        LOGIN_INFO: 'Informação do Login',
        LICENSING_TYPE: 'Selecionar Tipo Licenciamento',
        LICENSING_TYPE_REQ: 'Tipo Licenciamento é obrigatório.',
        LAST_PAYMENT_DATE: 'Data UltimoPagamento',
        LAST_PAYMENT_DATE_REQ: 'Dados UltimoPagamento são necessários.',
        LOCALIZAÇÃO: 'Localização',
        LICENSING_KEY: 'Chave Licenciamento',
        LICENSING_KEY_REQ: 'Chave de licença necessária.',
        MAC_ADDRESS: 'Mac Address',
        MAC_ADDRESS_REQ: 'Mac Address is required.',
        Mark_location_on_map: 'Marque a localização no mapa.',
        MAX_LOGIN_ALLOWED: 'Maximum login allowed.',
        MAX_LOGIN_ALLOWED_REQ: 'Maximum login allowed is required.',
        MODULES: 'Módulos',
        MODULE_CUSTOMER_SATISFACTION: 'Módulo Satisfação Cliente',
        MODULE_FORM: 'Módulo Formullarios',
        MODULE_MARKUP: 'Módulo Marcações',
        MODULE_PASSWORD: 'Módulo Senhas',
        MODULE_PRESENCE_JUSTIFICATION: 'Módulo PresenceJustification',
        MODULE_SMS: 'Módulo SMS',
        MODULE_VIDEOCALL: 'Módulo VideoCall',
        MODULE_VOUCHERS: 'Módulo Vouchers',
        MODULE_WhatsAppMessage: 'WhatsApp Message Module',
        NEW_QUIZ_CLIENT: 'Novo Cliente de Quiz',
        NOME: 'Nome',
        NOME_A_SER_USADO_NO_URL_PROPRIO: 'Nome a ser usado no URL proprio',
        NOME_CURTO: 'Nome Curto',
        NOME_CURTO_REQ: 'Nome Curto é Obrigatório.',
        NOME_EXIBIÇÃO: 'Nome Exibição',
        NOME_EXIBIÇÃO_REQ: 'Nome Exibição é Obrigatório.',
        NOME_REQ: 'O nome é Obrigatório',
        NR_LOCATIONS: 'Nr Locais',
        NR_LOCATIONS_REQ: 'Nr Locais é Obrigatório.',
        NR_ENTITES: 'Nr Entidades',
        NR_ENTITES_REQ: 'Nr Entidades é obrigatório.',
        NR_USERS: 'Nr Utilizadores',
        NR_USERS_REQUIRED: 'Nr Utilizadores é obrigatório.',
        NEW_CLIENT: 'Novo Cliente',
        NEW_LOCAL_COUNT: 'Nova contagem local',
        ORG: 'Organizacional',
        PLEASE_ENTER_VALID_DETAILS: 'Introduza detalhes válidos',
        PAIS_OPCIONAL: 'Pais (Opcional)',
        PLEASE_CONFIRM_THIS_ACTION: 'Confirme esta ação.',
        PLEASE_CONFIRM_THIS_ACTION_SECOND: 'Forneça sua confirmação para esta ação',
        PODE_MOSTRAR_O_NOME_DO_USUÁRIO_NO_MONITOR: 'Pode mostrar o nome do usuário no monitor',
        PODE_REDIRECIONAR_ANTES_DE_INICIAR_O_ATENDIMENTO: 'Pode redirecionar antes de iniciar o atendimento',
        PODE_REDIRECIONAR_APENAS_PARA_FUNCIONÁRIOS_ATIVOS: 'Pode redirecionar apenas para funcionários ativos',
        PODE_REDIRECIONAR_DEPOIS_DE_INICIAR_O_ATENDIMENTO: 'Pode redirecionar depois de iniciar o atendimento',
        PODE_REDIRECIONAR_PARA_SERVIÇO: 'Pode redirecionar para serviço',
        PODE_REDIRECIONAR_PARA_FUNCIONÁRIO: 'Pode redirecionar para Funcionário',
        PRODUCT: 'Produto',
        PASSWORD: 'Password',
        PASSWORD_REQ: 'PASSWORD necessária',
        PWD_MODULE: 'Módulo Senhas',
        PRESENT_DEC_MODULE: 'Módulo Declaracao Presença',
        REDIRECIONAMENTO: 'Redirection',
        REDIRECIONAMENTOS_PARA_SERVIÇO_SÃO_ATENDIDOS_POR_ORDEM: 'Redirecionamentos para serviço são atendidos por ordem',
        REGISTROS_DE_RASTREAMENTO_DE_API: 'REGISTROS DE RASTREAMENTO DE API',
        REGISTROS_DE_EXCEÇÃO: 'REGISTROS DE EXCEÇÃO',
        REVnSUB: 'Rever e enviar',
        RESPONSIBLE_NAME: 'Nome Responsável',
        RESPONSIBLE_NAME_REQ: 'Nome do Responsável é obrigatório',
        RESPONSIBLE_SNAME: 'Último Nome',
        RESPONSIBLE_SURNAME_REQ: 'Último Nome é obrigatório',
        SELECT_ÁREA_DE_NEGÓCIO: 'Selecionar Área de Negócio',
        SELECT_Culture: 'Selecionar Culture',
        SELECT_DIVISÃO_ADMINISTRATIVA: 'Selecionar Divisão Administrativa',
        SELECT_PAIS: 'Selecionar Pais',
        SELECT_ESTILO: 'Selecionar Estilo',
        SELECT_ZONA_PRINCIPAL: 'Selecionar Zona Principal',
        SELECT_ZONA_SECUNDÁRIA: 'Selecionar Zona Secundária',
        SELECT_Weather_Unit: 'Selecionar Weather Unit',
        SERVIÇO: 'Serviço',
        SRVPROAREA: 'Área de Prestação de Serviços',
        SRVPRQ: 'Área de Prestação de Serviços é necessária',
        SYS_TYPE: 'Tipo Sistema',
        SUIT_ORGnCOMP: 'Adequado a associações e empresas com sub-entidades ou sub-divisões.',
        TAMANHO_RECOMENDADO: 'Tamanho recomendado',
        TELE_CONTCT: 'Contacto Telefónico',
        TELE_CONTCT_REQ: 'Contacto Telefónico é necessário',
        TELE_CONTCT_INVALID: 'O contato telefônico é inválido.',
        THIS_CHANGE_WILL_UPDATE_THE_CONTRACT: 'esta alteração atualizará o contrato.',
        TO_CHOOSE: 'Escolher',
        TOTAL_LOCAL_COUNT: 'Contagem local total',
        UPDATE_QUIZ_CLIENT: 'Atualizar Cliente de Quiz',
        USERNAME: 'Nome do usuário',
        USERNAME_MUST_EMAIL: 'Nome de utilizador (deve ser e-mail)',
        USER_PASSWORD_WARNING: 'Se você deseja atualizar a senha de login do usuário, preencha o campo de senha. Caso contrário, deixe-o em branco, e a senha de login permanecerá inalterada.',
        USERNAME_REQ: 'Nome de utilizador é obrigatório',
        URL_DA_IMAGEM: 'Url da Imagem',
        URL_DO_MARCADOR: 'Url do Marcador',
        URL_IMAGEM: 'Url Imagem',
        URL_INFO: 'Url do local',
        USER: 'Utilizador',
        VIDEOCALL: 'Weather Unit',
        Weather_Unit: 'Weather Unit',
        WILL_CONTACT_YOU: 'Brevemente iremos contactá-lo antes de proceder à aprovação.',
        ZONA_PRINCIPAL_OPCIONAL: 'Zona Principal (Opcional)',
        ZONA_SECUNDÁRIA_OPCIONAL: 'Zona Secundária (Opcional)',
      }
    },
    DISPENSER: {
      LABEL: {
        ADD_DISPENSER: 'Adicionar modelo de dispensador',
        CONTROLLER_URL: 'URL do controlador',
        CONTROLLER_URL_REQ: 'URL do controlador necessário',
        CSS_URL: 'Css Url',
        CSS_URL_REQ: 'Css Url Obrigatório',
        EDIT_DISPENSER: 'Editar modelo de dispensador',
        HEADER_DESC: 'Descrição do cabeçalho',
        HEADER_DESC_REQ: 'Descrição do cabeçalho necessária',
        LOGO_URL: 'URL do logotipo',
        LOGO_URL_REQ: 'URL do logotipo necessário',
        NEW_DISPENSER_TEMPLATE: 'Novo modelo de dispensador',
        TEMPLATE_URL: 'URL do modelo',
        TEMPLATE_URL_REQ: 'URL do modelo necessário',
        THUMBNAIL_URL: 'URL da miniatura',
        THUMBNAIL_URL_REQ: 'Url da miniatura necessária',
      }
    },
    ERROR: {
      ACTIVO_REQUIRED: 'Status Ativo é obrigatório.',
      CLIENT_REQUIRED: 'Cliente é obrigatório.',
      DATA_FIM_CONTRATO_REQUIRED: 'Data de Fim do Contrato é obrigatória.',
      DATA_INICIO_CONTRATO_REQUIRED: 'Data de Início do Contrato é obrigatória.',
      DESCRIPTION_REQUIRED: 'Descrição é obrigatória.',
      EMAIL_REQUIRED: 'Email é obrigatório.',
      LAST_PAYMENT_DATE_REQUIRED: 'Data do Último Pagamento é obrigatória.',
      NO_VIDEOCALL_LOGINS: 'Nenhum login de videochamada permitido: o número máximo de logins de videochamada deve ser maior que zero.',
      PASSWORD_REQUIRED: 'Senha é obrigatória.',
      PERIOD_REQUIRED: 'Período do Contrato é obrigatório.',
      QTDE_ENTIDADES_REQUIRED: 'Número de Entidades é obrigatório.',
      QTDE_FORMULARIOS_REQUIRED: 'Número de Formulários é obrigatório.',
      QTDE_LOCAIS_REQUIRED: 'Número de Locais é obrigatório.',
      REQUIRED_AGENDA_MODULE: 'Isso é necessário: o módulo de agenda deve ser ativado.',
      TELEFONE_REQUIRED: 'Telefone é obrigatório.',
      TELEFONE_NUMBER: 'Por favor, insira um número de telefone válido.'
    },
    ENTIDADE: {
      LABEL: {
        ADD_DISPENSADOR: 'Adicionar Dispensador',
        ADD_ENTIDADE: 'Adicionar Entidade',
        COR_PRIMARY: 'Cor Primário',
        COR_PRIMARY_REQ: 'Cor primária necessária.',
        DISPLAY_NAME: 'Nome de exibição',
        EDIT_DISPENSADOR: "Editar Dispensador",
        EDIT_ENTIDADE: 'Editar entidade',
        ENTIDADE: 'Entidade',
        NAME_DISPLAY: 'Exibição do nome',
        NAME_DISPLAY_REQ: 'Exibição de nome necessária.',
        NEW_ENTIDADE: 'Nova Entidade',
        SHORT_NAME: 'Classificar nome',
        SORT_DESCRIPTION: 'Classificar descrição',
        SORT_DESCRIPTION_REQ: 'Descrição de classificação necessária.',
        TEXT_COLOR: 'Cor do Texto',
        TEXT_COLOR_REQ: 'Cor do texto necessária.',
        URL_LOGO: 'Logotipo da URL',
        URL_LOGO_REQ: 'Logotipo da URL obrigatório.'
      }
    },
    LABEL: {
      AACTIONS: 'ACTIONS',
      ACTIVE: 'Ativo',
      ADD_ADMIN: 'Adicionar Admin',
      ADD_ERROR_MSG: 'Adicionar algo errado .. !!',
      ADD_PARTNER: 'Adicionar Parceiro',
      ADD_SUCCESS_MSG: 'Adicionar dados com sucesso .. !!',
      ALL: 'Tudo',
      APROVADO: 'Aprovado',
      ASSOCIATE_TO_CLIENTS: 'Associação a Clientes',
      BY_BUTTON: 'Por botão',
      BY_EXTERNAL_API: 'Por External API',
      BY_EXTERNAL_PLUGIN: 'Por External Plugin',
      BY_MAP_PORTAL: 'Por Map Portal',
      BY_WEB_APP: 'Por Web App',
      BUTTON: 'Botão',
      CANCELLED: 'Cancelado',
      CLIENT: 'Cliente',
      CLIENTS: 'Clientes',
      CLIENTID: 'Id Cliente',
      CLOSE: 'Fechar',
      COMPANY_NAME: 'Nome da Empresa',
      COMPANY_NAME_WHITESPACE: 'O nome da empresa não pode conter espaços em branco.',
      CONTACT_NAME: 'Nome do Responsável',
      CONTACT_NAME_WHITESPACE: 'O nome de contato não pode conter espaços em branco.',
      ContractEndDATE: 'Data de Fim do Contrato',
      ContractStatus: 'Status do Contrato',
      DATA_FIM_CONTRATO: 'Data de Fim do Contrato',
      DATA_INICIO_CONTRATO: 'Data de Início do Contrato',
      DEACTIVE: 'Desativar',
      DEACTIVATED: 'Desativado',
      DELETE_ALL: 'Excluir Tudo',
      DELETE_ERROR_MSG: 'Erro ao deletar registro .. !!',
      DELETE_SUCCESS_MSG: 'Registro excluído com sucesso .. !!',
      DESCRIPTION: 'Descrição',
      DESCRIPTION_REQ: 'Descrição Necessária',
      EDIT_Admin: 'Editar Admin',
      EDIT_ERROR_MSG: 'Valor atualizado não foi encontrado .. !!',
      EDIT_PARTNER: 'Editar Parceiro',
      EDIT_SUCCESS_MSG: 'Dados atualizados com sucesso .. !!',
      EMAIL: 'Email',
      EMAIL_IS_VALID: 'Informe um endereço de e-mail válido.',
      EMAIL_WHITESPACE: 'O e-mail não pode conter espaços em branco.',
      END_DATE: 'Data Final',
      ENTITY_LIST: 'Lista de Entidades',
      EXPIRED: 'Expirado',
      FIRST_NAME: 'Nome',
      FILTER_BY_STATUS: 'Filtrar por Status',
      GracePeriod: 'Período de Carência',
      ID: 'ID',
      IN_ACTIVE: 'Inativo',
      INDIVUDUAL_CLIENT: 'Cliente individual',
      IsAdminOperator: 'É Administrador Operador',
      KEY_ERROR_MSG: 'Falha na Geração de Chaves .. !!',
      KEY_SUCCESS_MSG: 'Chave Gerada com Sucesso .. !!',
      LAST_NAME: 'Sobrenome',
      LAST_PAYMENT_DATE: 'Data do Último Pagamento',
      LEN_MOBILE: 'O número de telemóvel deve conter apenas caracteres numéricos e não pode conter espaços em branco',
      LOGIN_ID: 'Login',
      MAP_PORTAL: 'Portal do Mapa',
      MARK_LOCATION_IN_MAP: 'Marcar Localização no Mapa',
      MOBILE: 'Telemóvel',
      MYTURN_ADMIN_LIST: 'Lista de Admins',
      MYTURN_PARTNER_LIST: 'Lista de Parceiros MyTurn.',
      NAME: 'Nome',
      NAME_REQ: 'Nome Obrigatório.',
      NO_RECORDS_FOUND: 'Nenhum Registro Encontrado',
      OTHERS: 'Outro',
      PENDING: 'Pendente',
      PASS_MUST_8_LONG: 'Senha deve ter pelo menos 8 caracteres.',
      PASS_NOT_MATCH: 'As senhas não coincidem.',
      PASS_WHITESPACE: 'Senha não pode conter espaços em branco',
      QR_CODE: 'Código QR',
      REQ: 'Obrigatório!',
      RENEWAL: 'Renovação',
      SAVE: 'Salvar',
      SAVE_CHANGES: 'Salvar Alterações',
      SEARCH_IN_ALL_FIELDS: 'Pesquisar em Todos os Campos',
      SELECT_CLIENT: 'Selecionar Cliente',
      SELECT_LICENSESTATUS: 'Selecione o Status da Licença',
      SELECTED_RECORDS_COUNT: 'Contagem de Registros Selecionados',
      STARTDATE: 'Data de Início',
      STATUS: 'Status',
      SURNAME: 'Sobrenome',
      TOKEN: 'Token',
      TOTAL: 'Total',
      UNAPROVDO: 'Não Aprovado',
      URL: 'URL',
      VIEW_CLIENT_CONTRACT: 'Ver Contrato do Cliente',
      WEB_APP: 'Web APP',
      QTDE_ENTIDADES: 'Número de Entidades',
      QTDE_FORMULARIOS: 'Número de Formulários',
      QTDE_LOCAIS: 'Número de Locais',
      TELEFONE: 'Telefone',
      NEW_QUIZ_CLIENT: 'Novo Cliente do Quiz',
      ENTER_CONTARCT_PERIOD: 'Selecione o Período do Contrato',
      CONTRACT_PERIOD: 'Período do Contrato'
    },
    LOCAL: {
      LABEL: {
        ADD_LOCAL: 'Adicionar Local',
        EDIT_LOCAL: 'Editar Local',
        LAT: 'Latitude',
        LAT_REQ: 'Latitude necessária.',
        LNG: 'Longitude',
        LNG_REQ: 'Longitude necessária.',
        LOCAL: 'Local',
        NEW_LOCAL: 'Novo local',
        URL_INFO: 'Informação do URL',
        URL_INFO_REQ: 'Informação de URL necessária.'
      }
    },
    MENU: {
      ADMIN: 'Admin',
      BUSINESS_AREA: 'Area Negocio',
      CLIENT_CONTRACT_INFORMATION: 'Informações do contrato do cliente',
      CLIENTS: 'Clientes',
      CLIENT_REGISTRAION: 'Cadastro de cliente',
      CLIENT_CREATION: 'Registo de Cliente',
      CLIENT_MANAGEMENT: 'MyTurn Gestão de Clientes',
      CONFIGURATION: 'Configuração',
      CHANNELS: 'Canais',
      DASHBOARD: 'Painel',
      DISPENSER_TEMPLATE: 'Modelo de dispensador',
      ENTIDADE: 'Entidade',
      ENTIDADE_MANAGEMENT: 'Gestão de Entidades',
      END_DATE: 'End Date',
      LOCAL: 'Local',
      LOCAL_MANAGEMENT: 'Gestão de Locais',
      LOGS_TRACKING: 'Logs & Tracking',
      ManageAccess: 'Gerenciar Acesso',
      MONITOR_TEMPLATE: 'Modelo de monitor',
      PERMISSION_AREA_MAPPING: 'Permission Area Mapping',
      NUMBER_OF_TICKETS_BY_LOCAL_WITH_SUM: 'Number of Tickets by local (coma soma)',
      NUMBER_OF_TICKETS_BY_LOCAL: 'Number of Tickets by local',
      NUMBER_OF_USERS_LOGGED: 'Número de usuários logados',
      NO_OF_USERS_LOGGED_TO_THIS_ENTITY: 'Nº de usuários conectados a esta entidade',
      NO_OF_USERS_LICENSED_TO_THIS_ENTITY: 'Nº de usuários licenciados para esta entidade',
      Papels: 'Papels',
      PARTNER: 'Partner',
      PROCESS_RUNNING: 'Process',
      Quiz_CLIENT_MANAGEMENT: 'Quiz de Gestão de Clientes',
      ROLES: 'Papeis',
      ROLES_EDITING: 'Editar de funções',
      ROLES_USER_ASSOCIATION: 'Associação de usuários de funções',
      SMS_SETTINGS: 'SMS settings',
      SMS_CONFIGURATION: 'Configuração de SMS',
      SMS_PROVIDER: 'Provedor de SMS',
      START_DATE: 'Start Date',
      SYSTEM_PROCESSES: 'Processos do Sistema',
      STATISTICS_LIST: 'Lista de estatísticas',
      SCHEDULES: 'Horários',
      SYSTEM_LOGS_WITHSEARCH: 'Registros do sistema com pesquisa avançada',
      SYSTEM_LOGS: 'Registros do sistema',
      TICKETS: 'Senhas',
      TOOLS: 'Diversos',
      USER: 'Utilizador',
      USERS: 'Utilizadores',
      USER_CREATION: 'Criar de utilizador',
      USER_EDITING: 'Editar de utilizador',
      VIEWCLIENTCONTRACT: 'Ver Contrato do Cliente',
      WHATSAPP_SETTINGS: 'WhatsApp settings',
      WHATSAPP_CONFIGURATION: 'Configuração de WhatsApp',
      WHATSAPP_PROVIDER: 'WhatsApp Providers',
    },
    MONITOR: {
      LABEL: {
        ADD_MONITOR: 'Adicionar modelo de monitor',
        CONTROLLER_URL: 'URL do controlador',
        CONTROLLER_URL_REQ: 'URL do controlador necessário',
        EDIT_MONITOR: 'Editar modelo de monitor',
        NEW_MONITOR_TEMPLATE: 'Novo modelo de monitor',
        MONITOR_TEMPLATE: 'Modelo de monitor',
        TEMPLATE_URL: 'URL do modelo',
        TEMPLATE_URL_REQ: 'URL do modelo obrigatório',
        THUMBNAIL_URL: 'Url da miniatura',
        THUMBNAIL_URL_REQ: 'Url da miniatura necessária',
      }
    },
    PERMISSIONAREA: {
      LABEL: {
        ADD_PERMISSIONAREA: 'Add Permission Area Info',
        EDIT_PERMISSIONAREA: 'Edit Permission Area Info',
        USERPERMISSIONAREAMAPPING: 'User Permission Area Mapping',
        SAVE_PERMISSION: 'Save Permission Area Info',
      }
    },
    SMS: {
      LABEL: {
        ADD_SMS_PROVIDER: 'Adicionar provedor de SMS',
        ASSOCIATE: 'Associado',
        ASSOCIATE_TO_ENTITY: 'Associate To Entity',
        CREATEDDATE: 'Criar Data',
        EDIT_SMS_PROVIDER: 'Editar Sms Fornecedor',
        ENTITIES: 'Entidades',
        LOGIN_USERNAME: 'Nome de usuário de login',
        NEW_SMS_PROVIDER: 'Novo Provedor de SMS',
        OAUTHTOKEN: 'OAthToken',
        OPERATOR: 'Operador',
        OPERATOR_REQ: 'Operator required',
        SENDER: 'Remetente',
        SMS_PROVIDER: 'Provedor de SMS',
        TICKET: 'Ticket',
        URL: 'Url',
        URL_REQ: 'O URL é obrigatório.'
      }
    },
    SMSCONFIG: {
      LABEL: {
        ADD_SMS_CONFIG: 'Adicionar configuração de SMS',
        ASSOCIATE_TO_ENTITY: 'Associate To Entity',
        CODE: 'Código',
        DIFFERANCE_PASSWORDS: 'Diferenças de senhas',
        EDIT_SMS_CONFIG: 'Editar configuração de SMS',
        HAIR_STYLIST: 'Cabeleireiro',
        JFB_PRESENTATION: 'Apresentação JFB',
        NEW_SMS_CONFIG: 'Nova configuração de SMS',
        OTHER_PROJECTS: 'Outros Projetos',
        SMS_CONFIG: 'Configuração de SMS',
        SERVICEMGMT: 'Gerenciamento de Serviços',
        TEXTSMS: 'SMS de texto',
        TEXT_TO_MARCACAO: 'Marcacao de texto',
        TEXT_TO_PASSOWRD: 'Texto para senha',
        OPERATOR: 'Operador'
      }
    },
    WHATSAPP: {
      LABEL: {
        LAST_PURCHASED_COUNTS: 'Contagens da última compra',
        ADD_NEW_SMS: 'Adicionar SMS',
        ADD_WHATSAPP_PROVIDER: 'Add WhatsApp Provider',
        API_VERSION: 'API Version',
        ASSOCIATE: 'Associate',
        ASSOCIATE_TO_ENTITY: 'Associate To Entity',
        CREATEDDATE: 'Create Date',
        EDIT_WHATSAPP_PROVIDER: 'Edit WhatsApp Provider',
        ENTITIES: 'Entities',
        NEW_WHATSAPP_PROVIDER: 'New WhatsApp Provider',
        OAUTHTOKEN: 'OAthToken',
        PHONE_NUMBER_ID: 'Phone Number Id',
        PROVIDER_NAME: 'Provider Name',
        REQ: 'required !',
        REMAINING_SMSCOUNT: 'Contagem de SMS restante',
        SCHEDULE: 'Schedule',
        SHOULD_SAVEINTODB: 'deve salvar em db',
        TOTALSMS: 'SMS Totais',
        TICKET: 'Ticket',
        URL: 'Url',
        WHATSAPP_PROVIDER: 'WhatsApp Provider',
      }
    },
    WHATSAPPCONFIG: {
      LABEL: {
        0: '{0}',
        1: '{1}',
        2: '{2}',
        3: '{3}',
        4: '{4}',
        5: '{5}',
        6: '{6}',
        7: '{7}',
        8: '{8}',
        9: '{9}',
        10: '{10}',
        11: '{11}',
        12: '{12}',
        13: '{13}',
        14: '{14}',
        15: '{15}',
        16: '{16}',
        ADD_WHATSAPP_TEMPLATE: 'Adicionar modelo de mensagem',
        CODE: 'Código',
        CHARACTERS: 'personagem',
        DIGITS: 'dígitos',
        DIGITS_BASED_ON_12_HOUR_CLOCK: 'dígitos com base no relógio de 12 horas',
        DIGITS_BASED_ON_24_HOUR_CLOCK: 'dígitos com base no relógio de 24 horas',
        DISPLAY_NAME: 'Nome de exibição',
        EDIT_WHATSAPP_CONFIG: 'Editar modelo de mensagem',
        ENTITY_NOME: 'Nome da Entidade',
        FIRST_NAME: 'Primeiro nome',
        FOR_SCHEDULE_PREVIEW_VARIABLE: 'Para a variável de visualização do cronograma',
        IMAGE_URL: 'Image URL',
        JFB_PRESENTATION: 'JFB Presentation',
        LAST_NAME: 'Apelido',
        LOCAL_NOME: 'Nome do Local',
        MEDIA_TEMPLATE: 'Modelo de mídia (logotipo com mensagem)',
        NEW_MESSAGE_TEMPLATE: 'Novo modelo de mensagem',
        NO_PREVIEW: 'Nenhuma visualização disponível!',
        NOTE: 'Nota',
        NUMBER_DE_SENHAS_DE_DIFERENÇA: 'Number de Senhas de Diferença em relaçao á su vez para Send Notificaçao (by WhatsApp)',
        OTHER_PROJECTS: 'Outros projetos',
        PLEASE_DONT_USE: 'Por favor, não use',
        PROVIDER_NAME: 'Nome do provedor',
        REQ: 'é necessário',
        SCHEDULE_SECRET_ID: 'Agenda secreta ID',
        SELECT_PROVIDER: 'Selecionar Provedor',
        SERVICE_NOME: 'Nome do Serviço',
        SERVICEMGMT: 'Gerenciamento de serviços',
        SIMPLE_TEXT: 'Modelo de mensagem de texto simples',
        STOMATOLOGY: 'Stomatology',
        TEMPLATE_Lang_CODE: 'Código de idioma do modelo',
        TEMPLATE_NAME: 'Nome do modelo',
        TEMPLATE_TYPE: 'Tipo de modelo',
        TEMPLATE_VARIABLES: 'Variàveis para utilização no template',
        TEXT_LENGTH_CANNOT_BE_MORE_THAN: 'O comprimento do texto não pode ser maior que',
        TEXT_TO_SCHEDULE: 'Texto para Agendamento',
        TEXT_TO_SCHEDULE_PREVIEW: 'Texto para agendar visualização',
        TEXT_TO_TICKET: 'Senha',
        TEXT_TO_TICKET_PREVIEW: 'Texto para visualização do ticket',
        TICKET_NUMBER: 'Numero da Fila',
        TICKET_TAKEN_DAY: 'Apresenta o Dia',
        TICKET_TAKEN_MONTH: 'Apresenta o Mês',
        TICKET_TAKEN_TIME_HOUR: 'Apresenta os Minutos',
        TICKET_TAKEN_TIME_BASED_ON_24_HOUR_CLOCK: 'Igual à opcao 5 mas em formato 24h',
        TICKET_TAKEN_TIME_IN_DEFAULT_FORMAT: 'Data em formato',
        TICKET_TAKEN_TIME_MINUTES: 'Apresenta a Hora',
        TICKET_TAKEN_TIME_SECONDS: 'Apresenta os Segundos',
        TICKET_TAKEN_YEAR: 'Apresenta o Ano',
        TICKET_QUEUE: 'Letra da Fila',
        WAITING_TIME_IN_HOURS: ' Tempo de espera em horas',
        WAITING_TIME_IN_MINUTES: 'Tempo de espera em minutos',
        WHATSAPP_MESSAGE_TEMPLATES: 'Modelos de mensagem do WhatsApp',
        WILL_BE_USED_AS_BELOW: 'Será usado como abaixo',
        WHEN_YOU_ARE_USING_BELOW_VARIABLES_FOR_CUSTOM_DATE_TIME_FORMATTING: 'Quando você estiver usando as variáveis abaixo para formatação personalizada de data e hora'
      }
    },
    'Não tem acesso à acção pedida.': 'Não tem acesso à acção pedida.'
  }
}
