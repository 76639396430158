// USA
export const locale = {
  lang: 'en',
  data: {
    BUTTON: {
      BACK: 'Back',
      CANCEL: 'Cancel',
      NEXT_STEP: 'Next Step',
      PREVIOUS: 'Previous',
      RESET: 'Reset',
      SUBMIT: 'Submit',
      SAVE: 'Save',
      PRIVIEW: 'Preview',
      CHECKALL: 'Check All',
    },
    BUSINESS: {
      LABEL: {
        ADD_BUSINESS: 'Add Business Area',
        BUSINESS_AREA: 'Business Area',
        EDIT_BUSINESS: 'Edit Business Area',
        ICON: 'Icon',
        ICON_REQ: 'Icon Required.',
        NEW_BUSINESS_AREA: 'New Business Area',
      }
    },
    CLIENT: {
      LABEL: {
        ACC_CREATED: 'Account Created',
        ÁREA_DE_NEGÓCIO: 'Business Area',
        ÁREA_DE_NEGÓCIO_REQ: 'Business Area is required.',
        AVALIACAO_TOKEN: 'Avaliacao Token',
        AVALIACAO_TOKEN_REQ: 'Avaliacao Token is required.',
        C_PASSWORD: 'Confirm Password',
        C_PASSWORD_REQ: 'Confirm Password is required',
        C_PASSWORD_MATCH: 'Confirm Password is must be same as password',
        Celcius: 'Celcius',
        CHOSEN: 'Chosen',
        CLIENT: 'Client',
        CLIENT_NAME: 'Client Name',
        CLNTINFO: 'Customer Information',
        COMPANY: 'Company Name',
        COMPLT_COMPLT: 'Complete Population',
        COMPLT_REQUEST: 'Complete Registration',
        COMRQ: 'Company Name is required',
        CONFIRM_CRT_ACC: 'All data has been completed. Click the button to confirm the account creation. ',
        CONTACT: 'Contact',
        CONTACT_INFO: 'Contact Information',
        COR_DO_BANNER: 'Banner Color',
        COR_DO_TEXTO: 'Text Color',
        CONTACT_STATUS: 'Select Contract Status',
        CONTACT_STATUS_REQ: 'Contract status is required.',
        CONTRACT_PERIOD: 'Select Contract Period',
        CONTRACT_PERIOD_REQ: 'Contract Period is required.',
        CREATION_DATE: 'Creation Date',
        CREATION_DATE_REQ: 'Data Creation is required.',
        CONTACT_And_LOGIN_INFORMATION: 'Contact & Login Information',
        CONTRACT_START_DATE: 'Contract Start Date',
        CONTRACT_START_DATE_REQ: 'Contract Start is required.',
        CONTRACT_END_DATE: 'Contract End Date',
        CONTRACT_END_DATE_REQ: 'Contract End is required.',
        Close: 'Close',
        CLIENT_LIST: 'Client List',
        CLIENT_REGISTRAION: 'Client Registration',
        CREATE_ACCOUNT: 'Create account',
        CREATEDDATE: 'Created Date',
        CREATE_LOCATION: 'Create Location',
        CREATE_ENTIDADE: 'Create Entity',
        Culture: 'Culture',
        EMAIL: 'Email',
        DATE_RANGE: 'Date Range',
        DESCRIÇÃO: 'Description',
        DESCRIÇÃO_REQ: 'Description is required',
        DIVISÃO_ADMINISTRATIVA: 'Administrative division',
        EMAIL_REQ: 'Email is required',
        ENTIDADE_LOCAL: 'Entity Location',
        EDIT_CLIENT: 'Edit Client',
        ENTER_CONTACT_STATUS: 'Enter Contract Status',
        ENTER_CONTARCT_PERIOD: 'Enter Period of Contract',
        ENTER_LICENSING_TYPE: 'Enter Licensing Type',
        ENTER_MAX_LOGIN_ALLOWED: 'Enter Max Login Allowed',
        ENTER_NR_LOCATIONS: 'Enter No of. Locals',
        ENTER_NR_ENTITES: 'Enter No of. Entities',
        ENTER_NR_USERS: 'Enter No of. Users',
        ENTER_LICENSING_KEY: 'Enter Licensing Key',
        ENTIDADE: 'Entity',
        ERROSTACKTRACE: 'Error Stacktrace',
        EXCEÇÃO_INTERNA_DE_ERRO: 'Inner Exception',
        ESTILO_A_APLICAR_NA_ÁREA_DE_FUNCIONÁRIO: 'Style to apply in the Employee Area',
        Fahrenheit: 'Fahrenheit',
        GO_TO_HOME_PAGE: 'Go to the home page',
        GRACE_PERIOD: 'Gracing Period (in days)',
        GRACE_PERIOD_REQ: 'Gracing Period is required.',
        MORADA: 'Household',
        INDCTSRVPROAREA: 'Select the Service Provision Area',
        ID: 'Id',
        KEY_GEN: 'Key Gen',
        LOGIN: 'Login',
        LOGIN_INFO: 'Login Information',
        LICENSING_TYPE: 'Select Licensing Type',
        LOCAL: 'Place',
        LICENSING_TYPE_REQ: 'Licensing Type is required.',
        LAST_PAYMENT_DATE: 'Last Payment Date',
        LAST_PAYMENT_DATE_REQ: 'Last Payment Date is required.',
        LICENSING_KEY: 'Licensing Key',
        LICENSING_KEY_REQ: 'Licensing Key is required.',
        LOCALIZAÇÃO: 'Location',
        MAC_ADDRESS: 'Mac Address',
        MAC_ADDRESS_REQ: 'Mac Address is required.',
        MAX_LOGIN_ALLOWED: 'Maximum login allowed.',
        MAX_LOGIN_ALLOWED_REQ: 'Maximum login allowed is required.',
        Mark_location_on_map: 'Mark the location on the map.',
        MODULES: 'Modules',
        MODULE_CUSTOMER_SATISFACTION: 'Customer Satisfaction Module',
        MODULE_FORM: 'Form Module',
        MODULE_MARKUP: 'Bookings Module',
        MODULE_PASSWORD: 'Ticket Module',
        MODULE_SMS: 'SMS Module',
        MODULE_PRESENCE_JUSTIFICATION: 'Presence Justification Module',
        MODULE_VIDEOCALL: 'Video Call Module',
        MODULE_VOUCHERS: 'Vouchers Module',
        MODULE_WhatsAppMessage: 'WhatsApp Message Module',
        NEW_QUIZ_CLIENT: 'New Quiz Client',
        NOME: 'Name',
        NOME_A_SER_USADO_NO_URL_PROPRIO: 'Name to be used in the URL itself',
        NOME_CURTO: 'Short Name',
        NOME_CURTO_REQ: 'Short Name is Required',
        NOME_EXIBIÇÃO: 'Display Name',
        NOME_EXIBIÇÃO_REQ: 'Display Name is Required',
        NOME_REQ: 'Name is Required.',
        NR_LOCATIONS: 'No of. Locals',
        NR_LOCATIONS_REQ: 'No of. Locals is required.',
        NR_ENTITES: 'No of. Entities',
        NR_ENTITES_REQ: 'No of. Entities is required.',
        NR_USERS: 'No of. Users',
        NR_USERS_REQUIRED: 'No of. Users is required.',
        NEW_CLIENT: 'New Client',
        NEW_LOCAL_COUNT: 'New Local count',
        ORG: 'Organizational',
        PAIS_OPCIONAL: 'Country (Optional)',
        PLEASE_ENTER_VALID_DETAILS: 'Please enter valid details',
        PLEASE_CONFIRM_THIS_ACTION: 'Please confirm this action',
        PLEASE_CONFIRM_THIS_ACTION_SECOND: 'Kindly provide your confirmation for this action',
        PODE_MOSTRAR_O_NOME_DO_USUÁRIO_NO_MONITOR: 'Can show username on Monitor',
        PODE_REDIRECIONAR_ANTES_DE_INICIAR_O_ATENDIMENTO: 'You can redirect before starting the service',
        PODE_REDIRECIONAR_APENAS_PARA_FUNCIONÁRIOS_ATIVOS: 'Can only redirect to active employees',
        PODE_REDIRECIONAR_DEPOIS_DE_INICIAR_O_ATENDIMENTO: 'Can redirect after starting service',
        PODE_REDIRECIONAR_PARA_SERVIÇO: 'Can redirect to service',
        PODE_REDIRECIONAR_PARA_FUNCIONÁRIO: 'Can redirect to Employee',
        PRODUCT: 'Product',
        PASSWORD: 'Password',
        PASSWORD_REQ: 'Password is required',
        QUERY_STRING: 'String de consulta',
        REDIRECIONAMENTO: 'Redirection',
        REDIRECIONAMENTOS_PARA_SERVIÇO_SÃO_ATENDIDOS_POR_ORDEM: 'Redirects to service are served in order',
        REGISTROS_DE_EXCEÇÃO: 'Excepton Logs',
        REGISTROS_DE_RASTREAMENTO_DE_API: 'Api Tracking Logs',
        RESPONSIBLE_NAME: 'Responsible First Name',
        RESPONSIBLE_NAME_REQ: 'Responsible name is required',
        RESPONSIBLE_SNAME: 'Responsible Last Name',
        RESPONSIBLE_SURNAME_REQ: 'Responsible Last Name is required',
        REVnSUB: 'Review and Submit',
        SELECT_ÁREA_DE_NEGÓCIO: 'Select Business Area',
        SELECT_Culture: 'Select Culture',
        SELECT_DIVISÃO_ADMINISTRATIVA: 'Select Administrative division',
        SELECT_PAIS: 'Select Country',
        SELECT_ESTILO: 'Select Style',
        SELECT_Weather_Unit: 'Selecionar Weather Unit',
        SELECT_ZONA_PRINCIPAL: 'Select Main Zone',
        SELECT_ZONA_SECUNDÁRIA: 'Select Secondary Zone',
        SERVIÇO: 'Service',
        SETUP: 'Setup',
        SRVPROAREA: 'Service Provision Area',
        SRVPRQ: 'Service Provision Area is required',
        SYS_TYPE: 'System Type',
        SUIT_ORGnCOMP: 'Suitable for organizations and companies with sub-entities or sub-divisions.',
        TAMANHO_RECOMENDADO: 'Recommended size',
        TO_CHOOSE: 'Choose',
        TELE_CONTCT: 'Telephone Contact',
        TELE_CONTCT_REQ: 'Telephone Contact is required.',
        TELE_CONTCT_INVALID: 'Telephone Contact is invalid.',
        THIS_CHANGE_WILL_UPDATE_THE_CONTRACT: 'this change will update the contract.',
        TOTAL_LOCAL_COUNT: 'Total Local count',
        UPDATE_QUIZ_CLIENT: 'Update Quiz Client',
        USER: 'User',
        USERNAME: 'Username',
        USERNAME_MUST_EMAIL: 'Username (Must be Email)',
        USER_PASSWORD_WARNING: 'If you want to update the user\'s login password, please fill in the password field. Otherwise, leave it blank, and the login password will remain unchanged.',
        USERNAME_REQ: 'Username is required',
        URL_DA_IMAGEM: 'Image Url',
        URL_DO_MARCADOR: 'Url do Marcador',
        URL_IMAGEM: 'Url Image',
        URL_INFO: 'Location Url',
        Weather_Unit: 'Weather Unit',
        WILL_CONTACT_YOU: 'We will contact you shortly before proceeding with approval.',
        ZONA_PRINCIPAL_OPCIONAL: 'Main Zone (Optional)',
        ZONA_SECUNDÁRIA_OPCIONAL: 'Secondary Zone (Optional)',
      }
    },
    DISPENSER: {
      LABEL: {
        DISPENSER: 'Dispensador',
        ADD_DISPENSER: 'Add Dispenser Template',
        CONTROLLER_URL: 'Controller Url',
        CONTROLLER_URL_REQ: 'Controller Url Required',
        CSS_URL: 'Css Url',
        CSS_URL_REQ: 'Css Url Required',
        EDIT_DISPENSER: 'Edit Dispenser Template',
        HEADER_DESC: 'Header Description',
        HEADER_DESC_REQ: 'Header Description Required',
        LOGO_URL: 'Logo Url',
        LOGO_URL_REQ: 'Logo Url Required',
        NEW_DISPENSER_TEMPLATE: 'New Dispenser Template',
        TEMPLATE_URL: 'Template Url',
        TEMPLATE_URL_REQ: 'Template Url Required',
        THUMBNAIL_URL: 'Thumbnail Url',
        THUMBNAIL_URL_REQ: 'Thumbnail Url Required',
      }
    },
    ERROR: {
      ACTIVO_REQUIRED: 'Active status is required.',
      CLIENT_REQUIRED: 'Client is required.',
      DATA_FIM_CONTRATO_REQUIRED: 'Contract End Date is required.',
      DATA_INICIO_CONTRATO_REQUIRED: 'Contract Start Date is required.',
      DESCRIPTION_REQUIRED: 'Description is required.',
      EMAIL_REQUIRED: 'Email is required.',
      LAST_PAYMENT_DATE_REQUIRED: 'Last Payment Date is required.',
      NO_VIDEOCALL_LOGINS: 'No video call logins allowed: the maximum number of video call logins must be greater than zero.',
      PASSWORD_REQUIRED: 'Password is required.',
      PERIOD_REQUIRED: 'Contract Period is required.',
      PHONE_REQUIRED: 'Phone is required.',
      QTDE_ENTIDADES_REQUIRED: 'Number of Entities is required.',
      QTDE_FORMULARIOS_REQUIRED: 'Number of Forms is required.',
      QTDE_LOCAIS_REQUIRED: 'Number of Locations is required.',
      REQUIRED_AGENDA_MODULE: 'This is required: the agenda module must be enabled.',
      TELEFONE_REQUIRED: 'Telephone is required.',
      TELEFONE_NUMBER: 'Please enter a valid phone number.'
    },
    ENTIDADE: {
      LABEL: {
        ADD_DISPENSADOR: 'Add Dispensador',
        ADD_ENTIDADE: 'Add Entity',
        COR_PRIMARY: 'Cor Primary',
        COR_PRIMARY_REQ: 'Cor Primary Required.',
        DISPLAY_NAME: 'Display Name',
        EDIT_DISPENSADOR: "Edit Dispensador",
        EDIT_ENTIDADE: 'Edit entity',
        ENTIDADE: 'Entidade',
        NAME_DISPLAY: 'Name Display',
        NAME_DISPLAY_REQ: 'Name Display Required.',
        NEW_ENTIDADE: 'New Entidade',
        SHORT_NAME: 'Sort Name',
        SORT_DESCRIPTION: 'Sort Description',
        SORT_DESCRIPTION_REQ: 'Sort Description Required.',
        TEXT_COLOR: 'Text Color',
        TEXT_COLOR_REQ: 'Text Color Required.',
        URL_LOGO: 'Url Logo',
        URL_LOGO_REQ: 'Url Logo required.'
      }
    },
    LABEL: {
      AACTIONS: 'ACTIONS',
      ACTIVE: 'Active',
      ADD_ADMIN: 'Add Admin',
      ADD_ERROR_MSG: 'Something went wrong..!!',
      ADD_PARTNER: 'Add Partner',
      ADD_SUCCESS_MSG: 'Data added successfully..!!',
      ALL: 'All',
      APROVADO: 'Approved',
      ASSOCIATE_TO_CLIENTS: 'Association to Clients',
      BY_BUTTON: 'By Button',
      BY_EXTERNAL_API: 'By External API',
      BY_EXTERNAL_PLUGIN: 'By External Plugin',
      BY_MAP_PORTAL: 'By Map Portal',
      BY_WEB_APP: 'By Web App',
      BUTTON: 'Button',
      CANCELLED: 'Cancelled',
      CLIENT_CONTRACT_INFORMATION: 'Client Contract Information',
      CLIENTS: 'Clients',
      CLIENTID: 'Client Id',
      CLIENT: 'Client',
      CLOSE: 'Close',
      COMPANY_NAME: 'Company Name',
      COMPANY_NAME_WHITESPACE: 'Company Name cannot contain whitespace',
      CONTACT_NAME: 'Contact Name',
      CONTACT_NAME_WHITESPACE: 'Contact Name cannot contain whitespace',
      ContractEndDATE: 'Contract End Date',
      ContractStatus: 'Contract Status',
      COPY_ERROR_MSG: 'Copied fail!',
      COPY_SUCCESS_MSG: 'Copied!',
      DATA_FIM_CONTRATO: 'Contract End Date',
      DATA_INICIO_CONTRATO: 'Contract Start Date',
      DEACTIVE: 'Deactive',
      DEACTIVATED: 'Deactivated',
      DELETE_ALL: 'Delete All',
      DELETE_ERROR_MSG: 'Error in deleting record.. !!',
      DELETE_SUCCESS_MSG: 'Record deleted successfully..!!',
      DESCRIPTION: 'Description',
      DESCRIPTION_REQ: 'Description Required.',
      EDIT_Admin: 'Edit Admin',
      EDIT_ERROR_MSG: 'Updated value is not found..!!',
      EDIT_PARTNER: 'Edit Partner',
      EDIT_SUCCESS_MSG: 'Data updated successfully..!!',
      EMAIL: 'Email',
      EMAIL_IS_VALID: 'Enter a valid email address.',
      EMAIL_WHITESPACE: 'Email cannot contain whitespace.',
      END_DATE: 'End Date',
      EXPIRED: 'Expired',
      EXTERNAL_API: 'External API',
      EXTERNAL_PLUGIN: 'External Plugin',
      FILTER_BY_STATUS: 'Filter by status',
      FIRST_NAME: 'First Name',
      FIRST_NAME_WHITESPACE_VALI: 'First Name cannot contain whitespace',
      GracePeriod: 'Grace Period',
      ID: 'ID',
      IN_ACTIVE: 'In-Active',
      INDIVUDUAL_CLIENT: 'Individual client',
      INVALID_FORMAT: 'Invalid file formats.',
      IsADMIN_OPERATOR: 'Is Admin Operator',
      JOB_TRIGGERED: 'Job triggered successfully..!!',
      KEY_ERROR_MSG: 'Key generation failed..!!',
      KEY_SUCCESS_MSG: 'Key generated successfully..!!',
      LEN_MOBILE: 'Mobile must contain only numeric characters and cannot contain whitespace',
      LOGIN_ID: 'Login',
      MARK_LOCATION_IN_MAP: 'Mark Location in the Map',
      MAP_PORTAL: 'Map Portal',
      MOBILE: 'Mobile',
      MYTURN_ADMIN_LIST: 'Admin List',
      MYTURN_PARTNER_LIST: 'Myturn Partner List',
      NAME: 'Name',
      NAME_REQ: 'Name Required.',
      NO_RECORDS_FOUND: 'No Records Found',
      NOTIFY: 'Notify',
      OTHERS: 'Others',
      QR_CODE: 'QR CODE',
      RENEWAL: 'Renewal',
      REQ: 'is required !',
      SELECT_CLIENT: 'Select Client',
      SELECT_LICENSESTATUS: 'Select License Status',
      STATUS: 'Status',
      SSTATUS: 'STATUS',
      SAVE: 'Save',
      SAVE_CHANGES: 'Save changes',
      SEARCH_IN_ALL_FIELDS: 'Search in all fields',
      SMS: 'SMS',
      SELECTED_RECORDS_COUNT: 'Selected records count',
      SURNAME: 'Surname',
      TELEFONE: 'Telefone',
      TOKEN: 'Token',
      TOTAL: 'Total',
      UNAPROVDO: 'Unapproved',
      URL: 'URL',
      VIEW_CLIENT_CONTRACT: 'View client\'s contract',
      WEB_APP: 'Web APP',
      QTDE_LOCAIS: 'Number of Locations',
      QTDE_ENTIDADES: 'Number of Entities',
      QTDE_FORMULARIOS: 'Number of Forms',
      PASS_WHITESPACE: 'Password cannot contain whitespace',
      PASS_MUST_8_LONG: 'Password must be at least 8 characters long',
      PASS_NOT_MATCH: 'Passwords does not match.',
      CONF_PASSWORD_WHITESPACE: 'Confirm Password cannot contain whitespace',
      ENTER_CONTARCT_PERIOD: 'Select Contract Period',
      CONTRACT_PERIOD: 'Contract Period'
    },
    LOCAL: {
      LABEL: {
        ADD_LOCAL: 'Add Local',
        EDIT_LOCAL: 'Edit Local',
        LAT: 'Latitude',
        LAT_REQ: 'Latitude Required.',
        LNG: 'Longitude',
        LNG_REQ: 'Longitude Required.',
        LOCAL: 'Local',
        NEW_LOCAL: 'New Local',
        URL_INFO: 'URL Info',
        URL_INFO_REQ: 'URL Information Required.'
      }
    },
    MENU: {
      ADMIN: 'Admin',
      BUSINESS_AREA: 'Business Area',
      CLIENTS: 'Clients',
      CLIENT_CREATION: 'Client Creation',
      CLIENT_MANAGEMENT: 'MyTurn Client Management',
      CLIENT_REGISTRAION: 'Client Registration',
      CONFIGURATION: 'Configuration',
      CHANNELS: 'Channels',
      DASHBOARD: 'Dashboard',
      DISPENSER_TEMPLATE: 'Dispenser Template',
      END_DATE: 'End Date',
      ENTIDADE: 'Entidade',
      ENTIDADE_MANAGEMENT: 'Entity Management',
      LOCAL: 'Local',
      LOCAL_MANAGEMENT: 'Local Management',
      LOGS_TRACKING: 'Logs & Tracking',
      MONITOR_TEMPLATE: 'Monitor Template',
      ManageAccess: 'Manage Access',
      NUMBER_OF_TICKETS_BY_LOCAL_WITH_SUM: 'Number of Tickets by local (With Sum)',
      NUMBER_OF_TICKETS_BY_LOCAL: 'Number of Tickets by local',
      NUMBER_OF_USERS_LOGGED: 'Number of Users Logged',
      NO_OF_USERS_LOGGED_TO_THIS_ENTITY: 'No. of Users logged to this entity',
      NO_OF_USERS_LICENSED_TO_THIS_ENTITY: 'No. of Users Licensed to this entity',
      Papels: 'Roles',
      PARTNER: 'Partner',
      PROCESS_RUNNING: 'Process Running',
      PERMISSION_AREA_MAPPING: 'Permission Area Mapping',
      Quiz_CLIENT_MANAGEMENT: 'Quiz Client Management',
      ROLES: 'Roles',
      ROLES_EDITING: 'Roles Editing',
      ROLES_USER_ASSOCIATION: 'Roles User Association',
      SCHEDULES: 'Schedules',
      SMS_SETTINGS: 'SMS settings',
      SMS_CONFIGURATION: 'SMS Configuration',
      SMS_PROVIDER: 'SMS Provider',
      START_DATE: 'Start Date',
      STATISTICS_LIST: 'Statistics List',
      STATISTICS_LIST_PROVIDER: 'Statistics List Provider',
      SYSTEM: 'System',
      SYSTEM_LOGS: 'System Logs',
      SYSTEM_LOGS_WITHSEARCH: 'System Logs with Advance Search',
      SYSTEM_PROCESSES: 'System Processes',
      TICKETS: 'Tickets',
      TOOLS: 'Tools',
      USER: 'User',
      USERS: 'Users',
      USER_CREATION: 'Users Creation',
      USER_EDITING: 'Users Editing',
      VIEWCLIENTCONTRACT: 'View Client Contract',
      WHATSAPP_SETTINGS: 'WhatsApp settings',
      WHATSAPP_CONFIGURATION: 'Configuração de WhatsApp',
      WHATSAPP_PROVIDER: 'Provedor de WhatsApp',
    },
    MONITOR: {
      LABEL: {
        ADD_MONITOR: 'Add Monitor Template',
        CONTROLLER_URL: 'Controller Url',
        CONTROLLER_URL_REQ: 'Controller Url Required.',
        EDIT_MONITOR: 'Edit Monitor Template',
        NEW_MONITOR_TEMPLATE: 'New Monitor Template',
        MONITOR_TEMPLATE: 'Monitor Template',
        TEMPLATE_URL: 'Template Url',
        TEMPLATE_URL_REQ: 'Template Url Required.',
        THUMBNAIL_URL: 'Thumbnail Url',
        THUMBNAIL_URL_REQ: 'Thumbnail Url Required.',
      }
    },
    PERMISSIONAREA: {
      LABEL: {
        ADD_PERMISSIONAREA: 'Add Permission Area Info',
        EDIT_PERMISSIONAREA: 'Edit Permission Area Info',
        PERMISSIONAREAINFO: 'User Permission Area Info',
        USERPERMISSIONAREAMAPPING: 'User Permission Area Mapping',
        SAVE_PERMISSION: 'Save Permission Area Info',
      }
    },
    SMS: {
      LABEL: {
        ADD_SMS_PROVIDER: 'Add Sms Provider',
        ASSOCIATE: 'Associate',
        ASSOCIATE_TO_ENTITY: 'Associate To Entity',
        CREATEDDATE: 'Create Date',
        EDIT_SMS_PROVIDER: 'Edit Sms Provider',
        ENTITIES: 'Entities',
        LOGIN_USERNAME: 'Login Username',
        NEW_SMS_PROVIDER: 'New Sms Provider',
        OAUTHTOKEN: 'OAthToken',
        OPERATOR: 'Operator',
        OPERATOR_REQ: 'Operator required',
        SENDER: 'Sender',
        SMS_PROVIDER: 'Sms Provider',
        TICKET: 'Ticket',
        URL: 'Url',
        URL_REQ: 'URL is required.'
      }
    },
    SMSCONFIG: {
      LABEL: {
        ADD_SMS_CONFIG: 'Add Sms Config',
        ASSOCIATE_TO_ENTITY: 'Associate To Entity',
        CODE: 'Code',
        DIFFERANCE_PASSWORDS: 'Differance Passwords',
        EDIT_SMS_CONFIG: 'Edit Sms Config',
        HAIR_STYLIST: 'Hair stylist',
        JFB_PRESENTATION: 'JFB Presentation',
        NEW_SMS_CONFIG: 'New Sms Configuration',
        OTHER_PROJECTS: 'Other Projects',
        SMS_CONFIG: 'Sms Configuration',
        SERVICEMGMT: 'Service Management',
        TEXTSMS: 'Text Sms',
        TEXT_TO_MARCACAO: 'Text To Marcacao',
        TEXT_TO_PASSOWRD: 'Text To Passowrd',
        OPERATOR: 'Operator'
      }
    },
    ROLE: {
      LABEL: {
        ADD_ROLE: 'Add Role',
        EDIT_ROLE: 'Edit Role',
        ROLE_PERMISSION: 'Role Permission',
        ROLE: 'Role',
        SAVE_PERMISSION: 'Save Permission',
      }
    },
    WHATSAPP: {
      LABEL: {
        LAST_PURCHASED_COUNTS: 'Last Purchased Counts',
        ADD_NEW_SMS: 'Add New SMS',
        ADD_WHATSAPP_PROVIDER: 'Add WhatsApp Provider',
        API_VERSION: 'API Version',
        ASSOCIATE: 'Associate',
        ASSOCIATE_TO_ENTITY: 'Associate To Entity',
        CREATEDDATE: 'Create Date',
        EDIT_WHATSAPP_PROVIDER: 'Edit WhatsApp Provider',
        ENTITIES: 'Entities',
        NEW_WHATSAPP_PROVIDER: 'New WhatsApp Provider',
        OAUTHTOKEN: 'OAthToken',
        PHONE_NUMBER_ID: 'Phone Number Id',
        PROVIDER_NAME: 'Provider Name',
        REQ: 'required !',
        REMAINING_SMSCOUNT: 'Remaining SMS Count',
        SCHEDULE: 'Schedule',
        SHOULD_SAVEINTODB: 'should save into db',
        TOTALSMS: 'Total SMS',
        TICKET: 'Ticket',
        URL: 'Url',
        WHATSAPP_PROVIDER: 'WhatsApp Provider',
      }
    },
    WHATSAPPCONFIG: {
      LABEL: {
        0: '{0}',
        1: '{1}',
        2: '{2}',
        3: '{3}',
        4: '{4}',
        5: '{5}',
        6: '{6}',
        7: '{7}',
        8: '{8}',
        9: '{9}',
        10: '{10}',
        11: '{11}',
        12: '{12}',
        13: '{13}',
        14: '{14}',
        15: '{15}',
        16: '{16}',
        ADD_WHATSAPP_TEMPLATE: 'Add Message Template',
        CODE: 'Code',
        CHARACTERS: 'characters',
        DEFAULT_TIME_FORMAT: '{17} - WAITING TIME IN DEFAULT FORMAT EX: WHEN THE WAITING TIME IS LESS THAN 1H, RETURN IS MINUTES LIKE THIS 45MIN, IF THE WAITING TIME IS EQUAL OR GREATER 1HOUR, RETURN HOUR LIKE THIS 02H38',
        DIGITS: 'digits',
        DIGITS_BASED_ON_12_HOUR_CLOCK: 'digits based on 12 Hour clock',
        DIGITS_BASED_ON_24_HOUR_CLOCK: 'digits based on 24-hour clock',
        DISPLAY_NAME: 'Display Name',
        EDIT_WHATSAPP_CONFIG: 'Edit Message Template',
        ENTITY_NOME: 'Entity Name',
        FIRST_NAME: 'First Name',
        FOR_SCHEDULE_PREVIEW_VARIABLE: 'For schedule preview variable',
        IMAGE_URL: 'Image URL',
        JFB_PRESENTATION: 'JFB Presentation',
        LAST_NAME: 'Last Name',
        LOCAL_NOME: 'Nome do Local',
        MEDIA_TEMPLATE: 'Media template (Logo with Message)',
        NEW_MESSAGE_TEMPLATE: 'New Message Template',
        NO_PREVIEW: 'No Preview Available!',
        NOTE: 'Note',
        NOTIFY: 'Notify',
        NUMBER_DE_SENHAS_DE_DIFERENÇA: '{18}- Number of Difference tickets in relation to your time for Send Notification (by WhatsApp)',
        OTHER_PROJECTS: 'Other Projects',
        PLEASE_DONT_USE: "Please don't use",
        PROVIDER_NAME: 'Provider Name',
        REQ: 'is required !',
        SCHEDULE_SECRET_ID: 'Schedule secret Id',
        SELECT_PROVIDER: 'Select Provider',
        SERVICE_NOME: 'Service Name',
        SERVICEMGMT: 'Service Management',
        SIMPLE_TEXT: 'Simple text message template',
        SMS: 'SMS',
        STOMATOLOGY: 'Stomatology',
        TEMPLATE_Lang_CODE: 'Template Language Code',
        TEMPLATE_NAME: 'Template Name',
        TEMPLATE_NAME_FROM_META: 'Template Name From Meta',
        TEMPLATE_TYPE: 'Template Type',
        TEMPLATE_VARIABLES: 'Template Variables',
        TEXT_LENGTH_CANNOT_BE_MORE_THAN: 'Text length cannot be more than',
        TEXT_TO_SCHEDULE: 'Text to Schedule',
        TEXT_TO_SCHEDULE_PREVIEW: 'Text to Schedule Preview',
        TEXT_TO_TICKET: 'Text to Ticket',
        TEXT_TO_TICKET_PREVIEW: 'Text to Ticket Preview',
        TICKET_NUMBER: 'Ticket Number',
        TICKET_TAKEN_DAY: 'Ticket Taken Day',
        TICKET_TAKEN_MONTH: 'Ticket Taken Month',
        TICKET_TAKEN_TIME_HOUR: 'Ticket Taken Time Hour',
        TICKET_TAKEN_TIME_BASED_ON_24_HOUR_CLOCK: 'Ticket Taken Time Based on 24-Hour Clock',
        TICKET_TAKEN_TIME_IN_DEFAULT_FORMAT: 'Ticket Taken Time in Default Format',
        TICKET_TAKEN_TIME_MINUTES: 'Ticket Taken Time Minutes',
        TICKET_TAKEN_TIME_SECONDS: 'Ticket Taken Time Seconds',
        TICKET_TAKEN_YEAR: 'Ticket Taken Year',
        TICKET_QUEUE: 'Ticket Queue',
        WAITING_TIME_IN_HOURS: 'Waiting Time in Hours',
        WAITING_TIME_IN_MINUTES: 'Waiting Time in Minutes',
        WHATSAPP_MESSAGE_TEMPLATES: 'WhatsApp Message Templates',
        WILL_BE_USED_AS_BELOW: 'will be used as below',
        WHEN_YOU_ARE_USING_BELOW_VARIABLES_FOR_CUSTOM_DATE_TIME_FORMATTING: 'when you are using below variables for custom date time formatting'
      }
    },
    'Não tem acesso à acção pedida.': 'You do not have access to the requested action.'
  }
};
