export enum UserRole {
  SuperAdmin = 1000,
  Admin = 1001,
  Partner = 1002,
  Cliente = 1,
  GestorGlobal = 2,
  GestorDivisao = 3,
  GestorLocal = 4,
  GestorEntidade = 5,
  GestorEntidadeLocal = 6,
  Funcionario = 7,
  Relatorios = 9,
  API = 10,
  Dev = 100
}
